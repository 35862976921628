import React, { useState, useEffect } from 'react';
import { Box, Button, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel, TextField, Typography } from '@mui/material';
import QRGenerator from './QRGenerator';
import { uploadFileToFStorage } from '../../api_fs/apifs';
import { fsFetchFiles, fsSecureLink } from '../FS_CMS_ukraine_org/FileExplorer/fstools';
import Login from '../FS_CMS_ukraine_org/Login';

const QRTEMPLATE1 = 'https://api.rotary.org.ua/uploads/clubsitecreator/qrcode_generator/img/template1.png';
const QRTEMPLATE2 = 'https://api.rotary.org.ua/uploads/clubsitecreator/qrcode_generator/img/qrtemplate_classic.png';

const QrGeneratorPage = ({ rootFolder, defaultLogo, defaultUlr }) => {
  const [qrcodeurl, setQrcodeurl] = useState(defaultUlr);
  const [logoOption, setLogoOption] = useState('none');
  const [template, setTemplate] = useState('QRTEMPLATE1');
  const [generatedQR, setGeneratedQR] = useState(null);

  const root_folder = rootFolder;
  const [fsPath, setFsPath] = useState(root_folder);
  const [files, setFiles] = useState([]);
  const [shareLinks, setShareLinks] = useState({});

  useEffect(() => {
    fetchFiles();
  }, [fsPath]);

  const fetchFiles = async () => {
    const arrFiles = await fsFetchFiles(fsPath);
    if (arrFiles) {
      fetchShareLinks(arrFiles);
      setFiles(arrFiles);
    }
  };

  const fetchShareLinks = async (files) => {
    const links = {};
    for (const file of files) {
      if (!file.dir) {
        const sharelink = await fsSecureLink(file.path);
        links[file.name] = sharelink;
      }
    }
    setShareLinks(links);
  };

  const handleSave = () => {
    const canvas = document.querySelector('canvas');
    canvas.toBlob(async (blob) => {
      const file = new File([blob], 'qrcode.png', { type: 'image/png' });

      try {
        await uploadFileToFStorage(file, fsPath);
        console.log('QR code uploaded successfully!');
        fetchFiles();
      } catch (error) {
        console.error('Error saving QR code:', error);
      }
    });
  };

  useEffect(() => {
    let logo = null;
    if (logoOption === 'club') {
      logo = defaultLogo;
    } else if (logoOption === 'district') {
      logo = defaultLogo;
    }
    setGeneratedQR(
      <QRGenerator data={qrcodeurl} logo={logo} template={template} />
    );
  }, [qrcodeurl, logoOption, template]);

  const handleResult = (res) => {
    console.log(`handleResult res=${res}`);
    fetchFiles();
  };

  const handleFilelistupdate = () => {
    fetchFiles();
  };
  const handleDownload = () => {
    
  };
  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', mt: 5, p: 2, boxShadow: 3, borderRadius: 2, backgroundColor: '#f9f9f9' }}>
      <Typography variant="h4" component="div" textAlign="center" gutterBottom>
        QR Code Generator
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          label="URL"
          variant="outlined"
          value={qrcodeurl}
          onChange={(e) => setQrcodeurl(e.target.value)}
          fullWidth
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">Вибір лого</FormLabel>
          <RadioGroup
            value={logoOption}
            onChange={(e) => setLogoOption(e.target.value)}
          >
            <FormControlLabel value="none" control={<Radio />} label="Без лого" />
            <FormControlLabel value="club" control={<Radio />} label="З лого Клубу" />
            <FormControlLabel value="district" control={<Radio />} label="З лого Дистрикту" />
          </RadioGroup>
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">Вибір дизайну</FormLabel>
          <RadioGroup
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
          >
            <FormControlLabel
              value="QRTEMPLATE1"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={QRTEMPLATE1} alt="QRTEMPLATE1" style={{ width: 50, height: 50, marginRight: 8 }} />
                  QRTEMPLATE1
                </Box>
              }
            />
            <FormControlLabel
              value="QRTEMPLATE2"
              control={<Radio />}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <img src={QRTEMPLATE2} alt="QRTEMPLATE2" style={{ width: 50, height: 50, marginRight: 8 }} />
                  QRTEMPLATE2
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        {generatedQR}
      </Box>
      
      <Button variant="contained" color="primary" onClick={handleSave}>
          Зберегти в Сховище
        </Button>
        {/* <Button variant="contained" color="primary" onClick={handleDownload}>
          Скачати
        </Button> */}

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="div" gutterBottom>
          Збережені QR-коди
          <Login handleResult={handleResult} />
          <Button onClick={handleFilelistupdate}>Оновити</Button>
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {files.map((file, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {file.media === 'image' && <img src={shareLinks[file.name]} height='30px' alt={file.name} />}
              {file.name}
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default QrGeneratorPage;
