import { LOCALES } from '../../../i18n/locales';
import IMG1 from '../../../assets/content/icc/Vira.jpg'

export const getEvent3Landing = (lang) => {
    return defaultData_ukr;
};

const defaultData_ukr = [
    {
        id: 0,
        header: 'Магія Ротарі:',
        mheader: 'Магія Ротарі',
        murl: 'magic',
        
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/T2425EN-Logo-RGB.png',
                content: [
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/0-02-05-50d78af91c0661142f87cb3c0d50ef0f9e2b5c4ff60f4a8ba6a5ded479234f7f_cea378b366017a1e.jpg"},
                ]
            },
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Zone21/0-02-05-e8c8fb4242d5dd91c5dff66900772aa2820b96b63bdd633b610b8746b593d4a6_1aa2c67ec463f80e.jpg',
                content: [
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/urchick_title.jpg"},
                        
                    {LandingMessage2: `
                    Stephanie A. Urchick
                    Rotary International President-elect
                    8 January 2024

                    Hello, family of Rotary! I am honored to address the 24-25 class of district governors, the Irresistibles! And when I call you “my family,” I’m not just being kind. I really think of Rotary as my family.
                    I want to start today by putting a special focus on some very important members of our family of Rotary. In a couple of weeks, I’m traveling to Pakistan to meet with some amazing people: female health workers.

                    Ending polio everywhere means taking on this hard task, block by block, across Pakistan. It’s a difficult and, at times, dangerous mission. These women literally climb mountains to vaccinate children in remote locations. But that’s not even half of it. They fight misinformation and prejudice every step of the way. When we finally eradicate polio in Pakistan — and everywhere — it will be due in large part to these women.

                    So when I arrive in Pakistan to meet with these workers, I want them to know that even though they will never meet most of you, they have your support, and you treasure their contributions. So I want you to give them a standing ovation that I can share with them on my visit ... hold on ... as soon as I get my camera ready.
                    ...`

                    // In addition to your generous applause, the best way for you to support these health workers and the fight against polio is by raising awareness and raising money. It’s not too late to join or initiate a PolioPlus Society in your club or district. The Bill & Melinda Gates Foundation is still matching our donations 2-to-1, but only if we meet our annual fundraising goal of US$50 million.

                    // To help raise awareness, I need you to contact elected officials and other government leaders. Remind them that polio is still a threat. Push them to support polio eradication. Polio remains our top priority and requires our fullest commitment. But there is so much important work to do`
                    },
                
                    {LandingFileIcon: "pdf", text: "Повний текст звернення", 
                         link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/ia24-speech-urchick-theme-en.pdf"},
                ]
            },
            // {
            //     img: 'https://api.rotary.org.ua/uploads/clubsitecreator/rotarians/photo/1707082125220-171845835.jpg',
            //     content: [
            //         {LandingMessage2: `Мої незламні Ротарійці  Дистрикту 2232!

            //         Перш за все радий вітати всіх вас на Полтавській землі!

            //         Навчальні семінари для призначених  Офіцерів Дистрикту (ДТТС) та обраних Президентів Ротарі клубів (ПЕТС) є великою щорічною подією кожного Дистрикту Ротарі Інтернешнл. По суті, на цих навчаннях закладається підґрунтя успішності наступного 2024-2025 Ротарійського року.
                    
            //         Під час Міжнародної Асамблеї Ротарі Інтернешнл, що відбулась в січні 2024 року в місті Орландо, США,  Президентка  РІ Стефані Урчик офіційно оголосила Президентську тему   та кольори року. 
                    
            //         “The Magic of  Rotary” / “Магія Ротарі”. 
                    
            //         «Ротарійці створюють магію з кожним завершеним проєктом, кожним пожертвуваним  доларом та кожним новим членом», наголосила Стефані Урчик.
                    
            //         Особливість цих семінарів, що вони проводяться у військовий час. Понад два роки вся Україна, як  і значна частина прогресивної світової спільноти, живе в умовах війни. Більшість Ротарі клубів та  Ротарійців нашого Дистрикту  служать «Понад власне я» в цих важких умовах.   Ротарійські ХАБи, безліч проєктів допомоги  вимушеним переселенцям, дітям, лікарням, ДСНС, дитячі реабілітаційні табори, забезпечення громад питною водою, генераторами, медичним обладнанням… Всього не перелічити, що стало  можливим   завдяки   Фундації Ротарі  Інтернешнл та потужній міжнародній Ротарійській  допомозі. 
                    
            //         Ось в цьому і є МАГІЯ РОТАРІ!
            //         `},
            //         {LandingFileIcon: "pdf", text: "Повний текст звернення", 
            //             link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/Application of DGE to PETS.pdf"},
            //     ]
            // },
            
            {
                content: [
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/Khardin_v2_____jpg2_page-0001.png", fullsize: 'true'},
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/Khardin_v2_____jpg2_page-0002.png", fullsize: 'true'},
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/magic/Khardin_v2_____jpg2_page-0003.png", fullsize: 'true'},
                ]
            },
            
        ]
    },
    {
        id: 1,
        header: 'Ротарі Інтернешнл',
        mheader: 'Ротарі Інтернешнл',
        murl: 'ri',
        arrcontent: [
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/ri1.jpg',
                content: [
                    {LandingMessage2: `Що таке Ротарі?`},
                    {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/0-02-05-3d27bca69f56da543c782aa97814c77874730ff67820981f33d208b279d31c0d_7807b921b2a3d69e.mp4'},
                ]
            },
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/photos/0-02-05-55fce6f03ae84467797abf832c43e4e1823a2f52f95ba7ca5d37a3103492dcdb_e3d24aa7141bdd8.jpg',
                content: [
                    {LandingMessage2: `Основні документи Ротарі:`},
                    
                    {BR: true}, {LandingFileIcon: "pdf", text: "Конституція РІ.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/1709123957090-769767566.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Правила РІ.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/1709123962735-187766250.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Кодекс Засад РІ.pdf (квітень 2024р)", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/CoP_04-24.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Rotary Code of policies.pdf (April 2024)", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/code_of_policies_rotary_international_04-24.pdf"},
                    //{BR: true}, {LandingFileIcon: "pdf", text: "Словник Ротарі Термінів.pdf", 
                    //    link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/rotary_terminology.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Словник Ротарійських термінів.pdf (оновлено на початку 2024р.)", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/Глосарій-РІ-V12.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Action plan overview eng.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/action-plan-overview-en.pdf"},
                        
                    {BR: true},{BR: true},
                    {LandingMessage2: `Брендбук для Президентської Теми Року:`},
                    {BR: true}, {LandingFileIcon: "pdf", text: "branding-guidelines-en.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/2024-25-presidential-theme-branding-guidelines-en (2).pdf"},
                    {LandingDownloadButton: `Скачати файли Президентської теми`, link: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/theme-2024-25-logo-and-guidelines-en (2).zip'},
                ]
            },
            {
                header: 'Ще відео про Ротарі',
                content: [
                    {LandingMessage2: `Виступ Стефанії Урчік`},
                    {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/2024_001_GS1_Urchick_EN.mp4'},
                    {LandingMessage2: `Гімн Ротарі`},
                    {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/Rotary Anthem-HD.mp4'},
                ]
            },
        ]
    },
    {
        id: 1,
        header: 'Зона 21а:',
        mheader: 'Зона 21а',
        murl: 'Zone21a',
        arrcontent: [
            {
                content: [
                    
                    {LandingImage: "true", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/photos/0-02-05-0af0b3ee9831988b4f0092d7d695365b25878b4ba4ecd3f171aea253491752d2_f564b2130c628b29.jpg", fullsize: 'true'},
                    {BR: true}, {LandingLinkIcon: "Офіційний сайт Зони 21", link: "https://rotaryzone21.org/"},
                    
                ]
            },
            {
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Zone21/0-02-05-7e4a03096b7975d1978a90b1f18c57db46a2bac828d7f842d1e9bb84ea3599ca_ba5ce8ee226b5d8.jpg',
                        content: [
                            {LandingMessage: "- Директор РІ Зона 21 та 22, 2024-26"},
                            {LandingMessage: "- Румунія, Дистрикт 2241"},
                        ],
                        name: "Даніель Танасе",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Zone21/0-02-05-08492eab26f9f681470c441bf5cb8e4b7553db7261ae52923d7ff5442b6f0158_c2872907385a4a0c.jpg',
                        content: [
                            {LandingMessage: "- Ротарі Координатор Регіон 24 Зона 21"},
                            {LandingMessage: "- Австрія, Дистрикт 1920"},
                        ],
                        name: "Вальтер Ебнер",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Zone21/0-02-05-a5dd9f80919fb8de871299cb8f2fe9ce7b1a5eb9210a480c473c656c41e46d2d_a45803e77f46a111.jpg',
                        content: [
                            {LandingMessage: "- Координар Фундації Ротарі Регіон 24, Зона 21"},
                            {LandingMessage: "- Ізраїль, Дистрикт 2490"},
                        ],
                        name: "Хайм Кеннет",
                    },
                ]
            },
            {content: [

                {LandingMessage2: `Додаткова інформація:`},
                
                {BR: true}, {LandingFileIcon: "pdf", text: "zone_structure_maps.pdf", 
                    link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/1709122071105-434566810.pdf"},  
                {BR: true}, {LandingFileIcon: "pdf", text: "zone-structure-list.pdf", 
                    link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/RI/1709122071124-682650823.pdf"},
                    
            ]
            }
        ]
    },
    {
        id: 1,
        header: 'Дистрикт 2232:',
        mheader: 'Дистрикт 2232',
        murl: 'd2232',
        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/photos/0-02-05-33b6cad65e1abde211f93640dbfea02966a7021f8cd8e2ed132f276e4f36254d_fb8456940a7f98c7.jpg',
        content: [
            {LandingMessage2: `Документи для скачування:`},
            
            {BR: true}, {LandingFileIcon: "pdf", text: "Електронна версія робочого зошиту.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/club/1710579829169-92589728.pdf"},
            {BR: true}, {LandingFileIcon: "pdf", text: "Статут ГС.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125351259-417384664.pdf"},
            {BR: true}, {LandingFileIcon: "pdf", text: "Правила ГС.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124965199-900778234.pdf"},
            {BR: true}, {LandingFileIcon: "pdf", text: "Виписка.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124814847-470376449.pdf"},
            {BR: true}, {LandingFileIcon: "xls", text: "ICC Ukraine.xlsx", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/D2232/icc.xlsx"},
            {BR: true}, {LandingFileIcon: "pdf", text: "Положення про МДК.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/D2232/1709124472263-657875618.pdf"}, 
            {BR: true}, {LandingFileIcon: "docx", text: "Стратегічний план на 2023-26 роки.docx", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/D2232/STRATEGIC PLAN of D2232 2023-26 Ukr.docx"},
            {BR: true}, {LandingFileIcon: "pdf", text: "План Керівництва Д2232 2024-2025.pdf", link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/D2232/Plan_management_v2.pdf"},
            {BR: true}, {LandingLinkIcon: "Фінансові документи в закритій частині сайту", link: "https://rotaryd2232ukraine.org/tempshare/finance"},
            
        ]
               
    },
    {
        id: 2,
        header: 'Ротарі Клуб:',
        mheader: 'Ротарі Клуб',
        murl: 'club',
        arrcontent: [
            {
                content: [
                    {LandingYoutubeFrame: `title`, linkid: 'd6BKxrZpnUE'},
                    {LandingYoutubeFrame: `title`, linkid: 'hvEPM2zx2oA'},
                ]
            },
            {
                img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/p1.jpg',
                content: [
                    {BR: true},{BR: true},
                    
                    {BR: true}, {LandingFileIcon: "pdf", text: "Відкрий для себе Ротарі.ppt", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124814827-321459986.ppt"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Будь динамічним Клубом.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124814845-559728439.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Ідеологія Ротарі.pptx", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124814848-525568300.pptx"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Кандидат.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124814849-960289245.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Молодь.pptx", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124965169-471354252.pptx"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Основи Ротарі.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124965177-241227422.pdf"},

                    ]
                },
                {
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/p2.jpg',
                    content: [
                        {BR: true},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Правила ГС.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124965199-900778234.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Правила ІАК.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709124965200-433647773.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Правила РАК.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125151880-208985876.doc"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Правила РК.doc", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125151880-797419938.pdf"},

                    ]
                },
                {
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/p3.jpg',
                    content: [
                        {BR: true},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Стандартна Конституція ІАК.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125151882-578786069.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Стандартна Конституція РАК.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125151883-914845.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Стандартна Конституція РК.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125351253-242454012.pdf"},

                    ]
                },
                {
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/p4.jpg',
                    content: [
                        {BR: true},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Молодіжні програми.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125351266-564792417.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Тест здоров`я клубу.pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125351266-835888684.pdf"},
                    {BR: true}, {LandingFileIcon: "pdf", text: "Міжнародна діяльність.pptx", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125493504-530683131.pptx"},
                        
                    {BR: true}, {LandingFileIcon: "pdf", text: "Членство в Ротарі-фінансові аспекти. Вебінар для нових членів.pptx", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/1709125493968-110309487.pptx"},
                        
                    ]
                },
                {
                    img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/bufget.jpg',
                    content: [
                        {BR: true},
                        {BR: true}, {LandingLinkIcon: "Приклад бюджету клубу", link: "https://docs.google.com/spreadsheets/d/1LOvYDATLspiilzYfDGvkqX4TiJmaDOWe/edit?usp=sharing&ouid=112665934997745474067&rtpof=true&sd=true"},
                    
                        
                        {BR: true}, {LandingFileIcon: "pdf", text: "learning center course catalog (eng).pdf", 
                        link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/Club/learning-center-course-catalog-en.pdf"},
                    ]
                },
                {
                    content: [
                    {LandingMessage2: `Фільм від Урчик до Президентів`},
                    {LandingVideo: `Що таке Ротарі?`, link: 'https://rotary.webdamdb.com/directdownload.php?ti=209522001&tok=5JWviMQ5kiWUDcijst/FkQRR&token=$2y$10$0HG0onk15AUKoHdG4fjbWufeZ76UO42FdKz73VrkJj72rdDcZ7wEu'},
                    ]
                }
        ]
    },/*
    {
        id: 3,
        header: 'Офіцери Д2232:',
        mheader: 'Офіцери Д2232',
        murl: 'officers_',
        LandingBdRotariansPublic: 'icc,d2232officer',
    },*/
    {
        id: 3,
        header: 'Офіцери Д2232:',
        mheader: 'Офіцери Д2232',
        murl: 'officers',
        LandingCardsRotarians: [
            {CardsTitle: 'District Governors - Губернатори Дистрикту', 
                subcards: [
                {
                    rotarianId: 18, user_drole: 'Губернатор Дистрикту (2024-2025)',
                },
                {
                    rotarianId: 4, user_drole: 'Елект Губернатор Дистрикту (2025-2026)',
                },
                
                {
                    rotarianId: 19, user_drole: 'Номіні Губернатор Дистрикту (2026-2027)',
                },
                {
                    rotarianId: 20, user_drole: 'Immediate past District Governor',
                },
                ]
            },
            
            {CardsTitle: 'District Executive Secretary - Виконавчий Секретар Дистрикту', 
                subcards: [
                {
                    rotarianId: 80, user_drole: 'Голова',
                },
                ]
            },

            {CardsTitle: 'ROTARIANS SERVING ON OVER-DISTRICT LEVEL - Офіцери, які служать на поза-Дистриктному рівні',
                subcards: [
                {
                    rotarianId: 45, user_drole: 'Представник Дистрикту 2232 у Законодавчій раді',
                },
                {
                    rotarianId: 10, user_drole: 'Асистент Координатора Ротарі, Регіон 24 (Зона 21A)',
                },
                // {
                //     rotarianId: 46, user_drole: 'Асистент Координатора Фонду Ротарі, Регіон 24 (Зона 21A)',
                // },
                {
                    rotarianId: 47, user_drole: 'Член Комунікаційного Комітету RI/TRF.',
                },
                {
                    rotarianId: 125, user_drole: 'Асистент Координатора з публічного іміджу Ротарі, Регіон 24 (Зона 21А)',
                },
                ]
            },/*
            {CardsTitle: 'PAST DISTRICT GOVERNOR (INCLUDING SERVED FOR RID 2230) - Паст Губернатори Д2232 (Д2230)', 
                subcards: [
                    {
                        rotarianId: 48, user_drole: 'Паст Губернатор Д2230 2002-03',
                    },
                    {
                        rotarianId: 49, user_drole: 'Паст Губернатор Д2230 2011-12',
                    },
                    {
                        rotarianId: 50, user_drole: 'Паст Губернатор Д2230 2014-15',
                    },
                    {
                        rotarianId: 45, user_drole: 'Паст Губернатор Д2232 2016-17',
                    },
                    {
                        rotarianId: 51, user_drole: 'Паст Губернатор Д2232 2017-18',
                    },
                    {
                        rotarianId: 46, user_drole: 'Паст Губернатор Д2232 2018-19',
                    },
                    {
                        rotarianId: 47, user_drole: 'Паст Губернатор Д2232 2019-20',
                    },
                    {
                        rotarianId: 10, user_drole: 'Паст Губернатор Д2232 2020-21',
                    },
                    {
                        rotarianId: 52, user_drole: 'Паст Губернатор Д2232 2021-22',
                    },
                    {
                        rotarianId: 53, user_drole: 'Паст Губернатор Д2232 2022-23',
                    },
                    {
                        rotarianId: 20, user_drole: 'Паст Губернатор Д2232 2023-24',
                    },
                ]
            },*/
            {CardsTitle: 'GOVERNOR ASSISTANTS - Асистенти Губернатора', 
                subcards: [
                    {
                        rotarianId: 54, user_drole: 'Відповідальний за клуби: Львів, Львів-Центр. Львів Інтернешнл, Львів-Княжий, Львів-Леополіс, Львів Замок, Львів Ратуша, Львів Інноваційний, Трускавець, ПРК Україна Єдність',
                    },
                    {
                        rotarianId: 55, user_drole: 'Відповідальний за клуби: Рівне, Дубно, Луцьк Лучеськ, Луцьк Волинь Прайд, Коростень, Тернопіль Сіті',
                    },
                    {
                        rotarianId: 19, user_drole: 'Відповідальний за клуби: Київ, Київ-Центр, Київ-Сіті, Київ-Столиця, Київ Мультінешнл, Київ Синергія, Київ-Софія, Київ-Бальзак, Київ Адванс, Київ Інтернешнл Бізнес, Київ Золота Пектораль',
                    },
                    {
                        rotarianId: 56, user_drole: 'Відповідальний за клуби: Черкаси, Черкаси-Центр, Черкаси Інтернешнл, Черкаси Синергія, Полтава, Кобеляки, Вінниця Поступ',
                    },
                    {
                        rotarianId: 57, user_drole: 'Відповідальний за клуби: Харків, Харків-Сіті, Харків Дірект Хелп Інтернешнл, Харків-Мрія, Харків-Мультінешнл, Харків-Надія, Харків Перша Столиця, Харків Інтернешнл, Харків Нью Левел, Харків Фенікс, Харків Ренесанс, Харків Вікторі, Харків Спіріт, Слов`янськ',
                    },
                    {
                        rotarianId: 58, user_drole: 'Відповідальний за клуби: Дніпро Сіті, Дніпро Новий, Дніпропетровськ Дніпро Регіон, Запоріжжя, Запоріжжя-Хортиця, Кропивницький',
                    },
                    {
                        rotarianId: 59, user_drole: 'Відповідальний за клуби: Херсон, Миколаїв, Одеса, Одеса-Рішельє, Одеса Інтернешнл, Одеса Сі Енерджі, Ізмаїл, Алушта, Е-Клуб України, Сімферопіль, Визирка',
                    },
                    {
                        rotarianId: 60, user_drole: 'Відповідальний за клуби: Чернівці, Івано-Франківськ,  Івано-Франківськ-Станіславів, Ужгород, Ужгород-Скала, Рахів Центр Європи, Велятино Інтернешнл, Коломия',
                    },
                ]
            },
            
            {CardsTitle: 'Intercountry Committee - Міждержавні Комітети', 
                subcards: [
                {
                    rotarianId: 10, user_drole: 'ICC National Coordinator for Ukraine',
                },
                ]
            },
            
            {CardsTitle: 'Rotary Leadership Institute - Ротарійський Інститут Лідерства', 
                subcards: [
                {
                    rotarianId: 46, user_drole: 'Голова',
                },
                ]
            },
            
            {CardsTitle: 'Membership Committee - Комітет з питань членства', 
                subcards: [
                    {
                        rotarianId: 61, user_drole: 'Голова',
                    },
                    {
                        rotarianId: 62, user_drole: 'DISTRICT MEMBER ATTRACTION DISTRICT CHAIR',
                    },
                    {
                        rotarianId: 63, user_drole: 'New Club Development Committee Голова',
                    },
                    {
                        rotarianId: 35, user_drole: 'DISTRICT MEMBER ENGAGEMENT DISTRICT CHAIR',
                    },
                ]
            },
            
            {CardsTitle: 'Public Image Committee - Комітет з питань публічного іміджу', 
                subcards: [
                {
                    rotarianId: 9, user_drole: 'Голова',
                },
                ]
            },

            {CardsTitle: 'District Rotary Foundation Committee - Комітет з питань Фундації Ротарі', 
                subcards: [
                {
                    rotarianId: 64, user_drole: 'Голова',
                },
                {
                    rotarianId: 63, user_drole: 'Fundraising and Endowment Subcommittee Chair',
                },
                {
                    rotarianId: 65, user_drole: 'Annual Fund Subcommittee Chair',
                },
                {
                    rotarianId: 55, user_drole: 'Grants/STEWARDSHIP Subcommittee Chair',
                },
                {
                    rotarianId: 11, user_drole: `- Scholarship/Rotary Peace Fellowship Subcommittee Chair,
                        - DISTRICT VOCATIONAL TRAINING TEAM SUBCOMMITTEE CHAIR`,
                },
                {
                    rotarianId: 46, user_drole: 'Polio Plus Subcommittee Chair',
                },
                // {
                //     rotarianId: 66, user_drole: 'Member',
                // },
                {
                    rotarianId: 58, user_drole: 'PHF Society Coordinator',
                },
                ]
            },

            {CardsTitle: 'Finance Committee - Фінансовий Комітет', 
                subcards: [
                {
                    rotarianId: 67, user_drole: 'Голова',
                },
                // {
                //     rotarianId: 68, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 69, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 27, user_drole: 'Treasurer for UA',
                // },
                ]
            },

            {CardsTitle: 'DISTRICT LEARNING FACILITATOR COMMITTEE - Комітет з питань Ротарійського навчання', 
                subcards: [
                {
                    rotarianId: 19, user_drole: 'Голова',
                },
                {
                    rotarianId: 47, user_drole: 'Trainer',
                },
                {
                    rotarianId: 10, user_drole: 'Trainer',
                },
                {
                    rotarianId: 72, user_drole: 'Trainer, Vebinars',
                },
                {
                    rotarianId: 65, user_drole: 'Trainer',
                },
                {
                    rotarianId: 45, user_drole: 'Trainer',
                },
                ]
            },
            
            {CardsTitle: 'Alumni Committee - Комітет випускників Ротарійських програм', 
                subcards: [
                {
                    rotarianId: 70, user_drole: 'Голова',
                },
                ]
            },
            
            {CardsTitle: '2025 District Conference Committee - Комітет з підготовки Конференції Д2232 у Харкові 2025р', 
                subcards: [
                {
                    rotarianId: 119, user_drole: 'Голова',
                },
                ]
            },

            {CardsTitle: 'District Diversity, Equity and Inclusion Committee Комітет з питань різноманітності, рівності та інклюзії', 
                subcards: [
                {
                    rotarianId: 71, user_drole: 'Голова',
                },
                {
                    rotarianId: 72, user_drole: 'Member',
                },
                ]
            },
            {CardsTitle: 'DISTRICT NEW GENERATIONS SERVICE EXCHANGE - Комітет з питань професійного розвитку та обміну молоді 18-30 років', 
                subcards: [
                {
                    rotarianId: 13, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: '2025 RI Convention Promotion Committee - Комітет з промоції Конгресу РІ в Калгарі, Канада 2025р', 
                subcards: [
                {
                    rotarianId: 73, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Rotary Friendship Exchange - Комітет з питань дружніх обмінів', 
                subcards: [
                {
                    rotarianId: 74, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Community Service Committee - Комітет з питань служіння громаді', 
                subcards: [
                {
                    rotarianId: 75, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Інтернешнл Service Committee - Комітет з питань міжнародного служіння', 
                subcards: [
                {
                    rotarianId: 4, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Interact Committee - Комітет з питань Інтеракту', 
                subcards: [
                {
                    rotarianId: 76, user_drole: 'Голова',
                },
                ]
            },
            
            {CardsTitle: 'Rotaract Committee - Комітет з питань Ротаракту', 
                subcards: [
                {
                    rotarianId: 11, user_drole: 'Голова',
                },
                // {
                //     rotarianId: 77, user_drole: 'Голова',
                // },
                {
                    rotarianId: 78, user_drole: 'District Rotaract Representative',
                },
                ]
            },
            {CardsTitle: 'RYLA Committee - Комітет з питань Ротарійської відзнаки молодим лідерам та дитячих реабілітаційних табор', 
                subcards: [
                {
                    rotarianId: 22, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Youth Exchange Committee - Комітет з питань молодіжних обмінів', 
                subcards: [
                {
                    rotarianId: 79, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Rotary Bylaws and Procedure Committee - Комітет з питань правил та процедур', 
                subcards: [
                {
                    rotarianId: 45, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: 'Rotary Magazine "Rotariets" - журнал "Ротарієць"', 
                subcards: [
                {
                    rotarianId: 47, user_drole: 'Видавець журналу "Ротарієць"',
                },
                ]
            },
            {CardsTitle: '"Vocational Service Committee /Rotary Business Council - Комітет з професійного служіння, Ділова Рада Д2232"						', 
                subcards: [
                {
                    rotarianId: 13, user_drole: 'Голова',
                },
                // {
                //     rotarianId: 11, user_drole: 'Заступник голови з міжнародних питань',
                // },
                // {
                //     rotarianId: 16, user_drole: 'Заступник голови з питань членства',
                // },
                // {
                //     rotarianId: 15, user_drole: 'Заступник голови з організаційних питань',
                // },
                // {
                //     rotarianId: 14, user_drole: 'Заступник голови з економічних питань',
                // },
                // {
                //     rotarianId: 17, user_drole: 'Заступник голови з напряму молодіжного підприємництва',
                // },
                ]
            },
            {CardsTitle: 'Rotary Legacy Committee of D2232 - Комітет Ротарійської спадщини', 
                subcards: [
                {
                    rotarianId: 48, user_drole: 'Голова',
                },
                // {
                //     rotarianId: 49, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 20, user_drole: 'Member',
                // },
                ]
            },
            {CardsTitle: 'Rotary Spirit, culture and Art Committee - Комітет з питань духовност, культури та мистецтва', 
                subcards: [
                {
                    rotarianId: 120, user_drole: 'Співголова',
                },
                {
                    rotarianId: 81, user_drole: 'Співголова',
                },
                // {
                //     rotarianId: 67, user_drole: 'Співголова',
                // },
                // {
                //     rotarianId: 82, user_drole: 'Member',
                // },
                ]
            },



            {CardsTitle: '7 areas of focus - 7 напрямків діяльності: Basic education and literacy Committee - Комітет з питань освіти та грамотності', 
                subcards: [
                {
                    rotarianId: 43, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: '7 areas of focus - 7 напрямків діяльності: Community economic development Committee - Комітет з питань економічного розвитку', 
                subcards: [
                {
                    rotarianId: 83, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: '7 areas of focus - 7 напрямків діяльності: Disease prevention and treatment Committee - Комітет з запобігання хвороб', 
                subcards: [
                {
                    rotarianId: 84, user_drole: 'Голова',
                },
                // {
                //     rotarianId: 85, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 86, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 66, user_drole: 'Member',
                // },
                ]
            },
            {CardsTitle: `7 areas of focus - 7 напрямків діяльності: Maternal and child health, medical and oral health Committee - Комітет з питань медицини, стоматології та здоров'я матері та дитини`, 
                subcards: [
                {
                    rotarianId: 40, user_drole: 'Голова',
                },    
                // {
                //     rotarianId: 19, user_drole: 'Голова',//Кожина
                // },
                // {
                //     rotarianId: 4, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 20, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 87, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 88, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 12, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 40, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 66, user_drole: 'Member',
                // },
                // {
                //     rotarianId: 89, user_drole: 'Member',
                // },
                ]
            },
            {CardsTitle: `7 areas of focus - 7 напрямків діяльності: Peacebuilding and conflict prevention Committee - Комітет з питань миробудування та розв'язання конфліктів`, 
                subcards: [
                {
                    rotarianId: 54, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: '7 areas of focus - 7 напрямків діяльності: Water, sanitation, and hygiene Committee - Комітет з питань води, гігієни та санітарії', 
                subcards: [
                {
                    rotarianId: 65, user_drole: 'Голова',
                },
                ]
            },
            {CardsTitle: '7 areas of focus - 7 напрямків діяльності: Environment Committee - Комітет з питань навколишнього середовища', 
                subcards: [
                {
                    rotarianId: 15, user_drole: 'Голова',
                },
                ]
            },

            
            {CardsTitle: 'Комітет з проведення Міжнародної конференції Ротарі-клубів з міст, внесених до Списку всесвітньої спадщини ЮНЕСКО «Історичні міста ЮНЕСКО, спадщина миру».', 
                subcards: [
                ]
            },
            {CardsTitle: 'IT Committee - Комітет з ІТ', 
                subcards: [
                {
                    rotarianId: 8, user_drole: 'Голова',
                },
                ]
            },

            {CardsTitle: `INTER-COUNTRY COMMITTEES (ICC) - Mіждержавні Комітети (МДК)`, 
                subcards: [
                {
                    rotarianId: 22, user_drole: 'ICC Ukraine-Poland',
                },
                {
                    rotarianId: 23, user_drole: 'ICC Ukraine-Germany',
                },
                {
                    rotarianId: 24, user_drole: 'ICC Ukraine-Czech & Slovakia',
                },
                {
                    rotarianId: 25, user_drole: 'ICC Ukraine-Hungary',
                },
                {
                    rotarianId: 26, user_drole: 'ICC Ukraine-Georgia',
                },
                {
                    rotarianId: 27, user_drole: 'ICC Ukraine-Romania',
                },
                {
                    rotarianId: 28, user_drole: 'ICC Ukraine-France',
                },
                // {
                //     rotarianId: 29, user_drole: 'ICC Ukraine-Cyprus',
                // },
                {
                    rotarianId: 30, user_drole: 'ICC Ukraine-Bulgaria',
                },
                {
                    rotarianId: 31, user_drole: 'ICC Ukraine-Belgium & Luxembourg',
                },
                {
                    rotarianId: 32, user_drole: 'ICC Ukraine-Israel',
                },
                // {
                //     rotarianId: 33, user_drole: 'ICC Ukraine-Kroatia',
                // },
                {
                    rotarianId: 121, user_drole: 'ICC Ukraine-Kroatia',
                },
                {
                    rotarianId: 34, user_drole: 'ICC Ukraine-Lithuania',
                },
                {
                    rotarianId: 35, user_drole: 'ICC Ukraine-Armenia',
                },
                {
                    rotarianId: 36, user_drole: 'ICC Ukraine-Moldova',
                },
                {
                    rotarianId: 37, user_drole: 'ICC Ukraine-Turkey',
                },
                {
                    rotarianId: 38, user_drole: 'ICC Ukraine - USA',
                },
                {
                    rotarianId: 39, user_drole: 'ICC Ukraine - Austria',
                },
                {
                    rotarianId: 40, user_drole: 'ICC Ukraine - Great Britain&Ireland',
                },
                // {
                //     rotarianId: 19, user_drole: 'ICC Ukraine - Latvia',
                // },
                {
                    rotarianId: 41, user_drole: 'ICC Ukraine - Italy Malta San Marino',
                },
                {
                    rotarianId: 42, user_drole: 'ICC Ukraine-Spain',
                },
                {
                    rotarianId: 43, user_drole: 'ICC Ukraine - Portugal',
                },
                {
                    rotarianId: 44, user_drole: 'ICC Ukraine - Netherlands',
                },
                ]
            }
        ]
    },
    {
        id: 4,
        header: 'Відео та фото ресурси:',
        mheader: 'Медіа',
        murl: 'media',
        content: [
            {BR: true},
            {LandingMessage2: `Ролик: Офіцери Дистрикту 2232 на 2024-25 рік`},
            {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/VideoPets24_v2.mp4'},
            {BR: true},{BR: true},
            {LandingMessage2: `Про Україну презентація`},
            {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/video_ukraine.mp4'},
            {BR: true},{BR: true},
            {LandingMessage2: `Відеоролик Козацькому роду нема переводу`},
            {LandingVideo: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/video/video1.mp4'},
        ]
    },
    
    {
        id: 4,
        header: 'My Rotary:',
        mheader: 'My Rotary',
        murl: 'myrotarypage',
        // content: [
        //     //{LandingPdf: `Що таке Ротарі?`, link: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/Your Rotary leadership toolkit_EN.pdf'},
        //     {BR: true}, {LandingFileIcon: "pdf", text: "Все про My Rotary.pdf", 
        //         link: "https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/Your Rotary leadership toolkit_EN.pdf"},    


        // ]
        arrcontent: [
            {
                cards: [
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q1.jpg',
                        buttonUrl: "https://brandcenter.rotary.org/en-us/",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q2.jpg',
                        buttonUrl: "https://my.rotary.org/rcc-redirect",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q3.jpg',
                        buttonUrl: "https://my.rotary.org/en/secure/13161",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q4.jpg',
                        buttonUrl: "https://my.rotary.org/en/secure/showcase",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q5.jpg',
                        buttonUrl: "https://my.rotary.org/en/learning-reference/learn-topic/membership",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q6.jpg',
                        buttonUrl: "https://my-cms.rotary.org/en/document/membership-assessment-tools",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q7.jpg',
                        buttonUrl: "https://my.rotary.org/en/who-we-are/about-rotary/diversity-equity-and-inclusion",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q8.jpg',
                        buttonUrl: "https://my.rotary.org/en/club-flexibility",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q9.jpg',
                        buttonUrl: "https://my.rotary.org/en/who-we-are/about-rotary/action-plan",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q10.jpg',
                        buttonUrl: "https://my.rotary.org/en/document/strategic-planning-guide",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q11.jpg',
                        buttonUrl: "https://my.rotary.org/en/knowledge-and-resources/resources-and-reference/policies-and-procedures",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q12.jpg',
                        buttonUrl: "https://my.rotary.org/en/participate",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q13.jpg',
                        buttonUrl: "https://youtu.be/hvEPM2zx2oA",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q14.jpg',
                        buttonUrl: "https://youtu.be/d6BKxrZpnUE",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q15.jpg',
                        buttonUrl: "https://my.rotary.org/en",
                    },
                    {
                        img: 'https://api.rotary.org.ua/uploads/clubsitecreator/D2232/PersonalFolder24/myrotary/q16.jpg',
                        buttonUrl: "https://my.rotary.org/en/contact/representatives",
                    },
                ]
            }
        ]
    },
    {
        id: 4,
        header: 'Події Дистрикту:',
        mheader: 'Події Дистрикту',
        murl: 'events2425',
        LandingEventFrame: {date1: '01.07.2024', date2: '01.07.2025'}
    }
]
